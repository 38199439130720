import React from 'react';
import './stylesHeader.css';

const Header = () => (
    <header className='home-header'>
        <h2 className='capitalize myh2 center'>My price action journey</h2>
        {/* <h1 className='headerH1'>My Daily Trade Analysis</h1> */}

        <h3 className='headerQuote'>The Kaizen trading philosophy</h3>
        <img className='kaizen' src="/assets/images/kaizenPhilosophy.png" alt="The Kaizen Philosophy" />
        <br /><br />
        <h5 className='myh5 dashMarks'>My trading system</h5>
        <p className='myh5 kaizenText system_0'>I must always follow my hard exit rule > +6 tick profit on every single trade! My target is fixed at +6 ticks.</p>
        <p className='myh5 kaizenText system_1'>I use the 2000-tick chart to identify the current main trend and the trend direction.</p> 
        <p className='myh5 kaizenText system_2'>I use the 500-tick chart as my entry chart where I place my orders at significant key entry levels.</p> 
        <p className='myh5 kaizenText system_3'>EMA 21 bar look back period (2000-tick chart), EMA 10 bar look back period (500-tick chart).</p> 
        <p className='myh5 kaizenText system_4'>White dotted drawing tools (2000-tick chart), golden drawing tools with solid lines (500-tick chart).</p> 
        <p className='myh5 kaizenText system_5'>I Identify the current main trend direction on the 2000-tick chart, by drawing channels or ranges. I look for key level areas where expect price to reverse or stall when I have established the current bias and where I expect price to stall or continue I focus on the entry chart analysis (500-tick chart).</p> 
        <p className='myh5 kaizenText system_6'>When I have the current bias I draw the lower time frame channels and ranges to find the KEPs (key entry points) on my 500-tick entry chart. I must trust the bias and enter on good signal bars at the KEPs, such as around the EMA, at the channel trend line or at the support/resistance levels in micro ranges.</p> 
        <h5 className='myh5 dashMarks'>My path to continuous improvement</h5>
        <p className='myh5 kaizenText'>Kaizen is a Japanese philosophy of continuous improvement, which emphasizes the importance of making small, incremental changes to my trading strategy and trading habits in order for me to achieve long-term success. My focus lays on a small, steady progress rather than on big, drastic changes.</p> 
        <p className='myh5 kaizenText'>This approach is particularly useful for me as a trader, as the markets are constantly evolving and changing, therefore it is important for me to be able to adapt and improve in my response to these changes. I believe that the Kaizen mindset will be a powerful tool for me as a trader, when looking to improve my performance and to achieve greater success in my trading.</p>
        <p className='myh5 kaizenText'></p>
       
        <h5 className='myh5 dashMarks'>What I'm trading</h5>
        <p className='myh5 kaizenText'>I am trading CL, GC, ES and NQ futures. On CL & GC I'm trading a 200 tick chart, on ES I'm trading a 2000 tick chart and on the NQ I'm trading the 1000 tick chart. I trade CL & GC during the London session and from the US open I trade all four instruments.</p>
       
        <h5 className='myh5 dashMarks'>Learning inspiration</h5>
        <p className='myh5 kaizenText'>I follow the teachings of Al Brooks and Mack (PATsTrading)</p>
      
        <h5 className='myh5 dashMarks'>Search tags</h5>
        <p className='myh5 instrumentSearchTags center'>'CL', 'GC', 'ES', 'NQ'</p>
        <p className='myh5 chartSearchTags center'>'Up', 'Down', 'Sideways', 'Range', 'Unclear', 'Mixed', or 'Bar by bar'</p>
        <p className='myh5 learningSearchTags center'>'The Trading Process', 'Laptop', 'Chart setup'</p>
    </header> 
);

export default Header
